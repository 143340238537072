const Java = () => {
  return (
    <div id='java'>
      <img id='javaImg' src='./images/javaicon.png' alt='javaicon'/>
      <h4 id='javaText'>Java</h4>
    </div>
  )
}

export default Java
