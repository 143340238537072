const CProject = () => {
    return (
      <div id='udptotcp'>
        <img src='./images/udptotcp.jpg' alt='udptotcp'/>
        <div id='udptotcpInfo'>
          <h3>UDP to TCP</h3>
          <p>The purpose of the client, server, and proxy programs is to transmit data reliably from the client to the server over UDP with IPv4 or IPv6 communication using the stop-and-wait protocol.</p>
          <p>C</p>
          <p><a href='https://github.com/nsinghsidhu12/udp-to-tcp' target='_blank' rel='noreferrer'>GitHub</a></p>
        </div>
      </div>
    )
  }
  
  export default CProject
  