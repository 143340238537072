const Visual = () => {
  return (
    <div id='visual'>
      <img id='visualImg' src='./images/visualicon.png' alt='visualicon'/>
      <h4 id='visualText'>Visual Studio Code</h4>
    </div>
  )
}

export default Visual
