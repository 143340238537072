const Website = () => {
  return (
    <div id='website'>
      <img src='./images/website.png' alt='website'/>
      <div id='websiteInfo'>
        <h3>Sports Cards Price Watcher</h3>
        <p>Created a personal Discord bot application to scrape sports card prices from COMC and notify when a card's price is equal to or below a set price.</p>
        <p>Node.js, JavaScript, SQLite</p>
        <p><a href='https://github.com/nsinghsidhu12/sports-cards-price-watcher' target='_blank' rel='noreferrer'>GitHub</a></p>
      </div>
    </div>
  )
}

export default Website
