const CSS = () => {
  return (
    <div id='css'>
      <img id='cssImg' src='./images/cssicon.png' alt='cssicon'/>
      <h4 id='cssText'>CSS</h4>
    </div>
  )
}

export default CSS
