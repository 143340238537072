const Bootstrap = () => {
  return (
    <div id='bootstrap'>
      <img id='bootstrapImg' src='./images/bootstrapicon.png' alt='bootstrapicon'/>
      <h4 id='bootstrapText'>Bootstrap</h4>
    </div>
  )
}

export default Bootstrap
